import { render, staticRenderFns } from "./FamiliesBenefList.vue?vue&type=template&id=98f99a6c&scoped=true&"
import script from "./FamiliesBenefList.vue?vue&type=script&lang=js&"
export * from "./FamiliesBenefList.vue?vue&type=script&lang=js&"
import style0 from "./FamiliesBenefList.vue?vue&type=style&index=0&id=98f99a6c&scoped=true&lang=css&"
import style1 from "./FamiliesBenefList.vue?vue&type=style&index=1&id=98f99a6c&lang=scss&scoped=true&"
import style2 from "./FamiliesBenefList.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98f99a6c",
  null
  
)

export default component.exports