import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // .post("/api/all_addbinifit", { family_id: this.id })
    async fetchFamilies(ctx, queryParams) {
       return new Promise((resolve, reject) => {
        let url=`api/v1/beneficiaries_page/get_families?page_size=${queryParams.page_size}&search=${queryParams.search}&page=${queryParams.page}`

     axios
          .get(url)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });

    },
    editbarcode(ctx, { id ,number}) {
      return new Promise((resolve, reject) => {

        let url = `api/v1/pending_actions/families/${id}/change_card_number`;
        axios
          .put(url,{number:number})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })},

    fetchFamilieSer(ctx, { id }) {
      return new Promise((resolve, reject) => {

        let url = `/api/v1/families/${id}/get_services_for_whole_family`;
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })},
    fetchFamiliesBenef(ctx, queryParams) {
      return new Promise((resolve, reject) => {

        let url = `/api/v1/families/${queryParams.id}/persons?page_size=${queryParams.page_size}&page=${queryParams.page}`;
        axios
          .get(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchBenef(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/addbinifit/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchModification(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/information_manager/modifications', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchAddition(ctx,queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/information_manager/pending_create_actions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    sendResponseaAdd(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approval_status:approvestate.approvestate
        }

        let url=`/api/v1/information_manager/createActions/${approvestate.action_id}`
        axios
          .put(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    responseAll(ctx,approvestate) {
      //console.log(approvestate)
      return new Promise((resolve, reject) => {


        let url=`/api/v1/information_manager/modifications/respond_multiple`
        axios
          .put(url,approvestate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    responseAllAddition(ctx,approvestate) {
      //console.log(approvestate)
      return new Promise((resolve, reject) => {


        let url=`/api/v1/information_manager/createActions/respond_multiple`
        axios
          .put(url,approvestate)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    sendResponse(ctx,approvestate) {
      return new Promise((resolve, reject) => {
        let data={
          approval_status:approvestate.approvestate
        }

        let url=`/api/v1/information_manager/modifications/${approvestate.action_id}`
        axios
          .put(url,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },

  },
}
